<template>
  <div>
    <div><h2>{{ fullName }}</h2></div>

    <!-- Allow editing of status and notes and submitting changes, if user is admin -->
    <b-form
      v-if="userCanSeeDetails"
      @submit.stop.prevent="Submit"
    >
      <b-row>
        <b-col>
          <a :href="'mailto:' + email">{{ email }}</a>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <a :href="HireProfileUrl()">Hire Profile</a>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-link :to="{ name: 'PrintApplicant', params: { userId: applicant.id } }">
            Print Applicant
          </router-link>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <span id="added-on">Added {{ DateFormatter(applicant.dateCreated) }}</span>
          &nbsp;
          <span id="updated-on">Updated {{ DateFormatter(applicant.dateLastUpdatedByUser) }}</span>
        </b-col>
      </b-row>

      <b-container v-if="canEdit">
        <b-form-row class="justify-content-center">
          <b-col>
            <b-link
              id="delete-applicant-link"
              v-b-modal.delete-modal
            >
              Delete Applicant
            </b-link>
            <b-modal
              id="delete-modal"
              title="Delete Applicant"
              :disabled="deleting"
              ok-variant="danger"
              @ok="DeleteApplicant"
            >
              <p>Delete {{ fullName }}?</p>
            </b-modal>
          </b-col>
        </b-form-row>

        <b-form-row class="justify-content-center">
          <b-col>
            Status:
            <status-menu
              :applicant="this.mutableApplicant"
              @status-changed="SetStatus($event)"
            />

            Excitement Level:
            <excitement-level-menu
              :applicant="this.mutableApplicant"
              @excitement-level-changed="SetExcitementLevel($event)"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-textarea
              id="applicant-notes-field"
              v-model="mutableApplicant.notes"
              placeholder="Notes"
            />
          </b-col>
        </b-form-row>

        <b-button
          id="user-info-submit"
          :disabled="!hasChanges || saving"
          type="submit"
          variant="primary"
        >
          <b-spinner
            v-if="saving"
            small
            class="mb-1"
          />
          Submit
        </b-button>
      </b-container>
      <b-container v-else>
        <p style="text-align: center">
          Status: {{ applicant.status }}
          Excitement Level: {{ excitementLevelLabel(applicant.excitementLevel) }}
        </p>
        <p style="text-align: left">
          {{ applicant.notes }}
        </p>
      </b-container>

      <b-alert
        :show="requestError"
        variant="danger"
        fade
      >
        {{ requestError }}
      </b-alert>

      <b-alert
        :show="successMessage ? 4 : 0"
        variant="success"
        dismissible
        fade
      >
        {{ successMessage }}
      </b-alert>
    </b-form>
  </div>
</template>

<script>

//import axios from 'axios'
import { mapGetters } from "vuex"
import StatusMenu from "@/components/StatusMenu"
import ExcitementLevelMenu from "@/components/ExcitementLevelMenu"
import excitementLevelManager from "@/utils/excitementLevels"
import formatters from "@/utils/formatters"

export default {
  name: "ApplicantInfo",
  components: {
    StatusMenu,
    ExcitementLevelMenu
  },
  props: { applicant: Object },

  data() {
    return {
      mutableApplicant: { ...this.applicant },
      savedApplicant: { ...this.applicant },
      requestError: null,
      successMessage: null,
      saving: false,
      deleting: false
    }
  },

  computed: {
    // Map getters from store
    ...mapGetters(["userIsRecruiter"]),

    canEdit() {
      return this.userIsRecruiter
    },

    fullName() {
      return this.applicant.firstName + " " + this.applicant.lastName
    },

    email() {
      return this.applicant.emailAddress
    },

    hasChanges() {
      return (
        (this.mutableApplicant.notes != this.savedApplicant.notes)
        || (this.mutableApplicant.status != this.savedApplicant.status)
        || (this.mutableApplicant.excitementLevel != this.savedApplicant.excitementLevel)
      )
    },

    userCanSeeDetails() {
      return this.userIsRecruiter
    },
  },

  methods:
    {
      HireProfileUrl() {
        return this.applicant.atsUrl
      },

      DateFormatter(isoFormatVal) {
        return formatters.convertIsoFormattedUtcStringToLocalDateString(isoFormatVal)
      },

      SetStatus(status) {
        this.mutableApplicant.status = status
      },

      SetExcitementLevel(level) {
        this.mutableApplicant.excitementLevel = level
      },

      Submit() {
        if (this.saving) {
          return
        }
        this.saving = true
        this.successMessage = null
        this.requestError = null
        this.$put(`/users/${this.applicant.id}`,
          { ...this.mutableApplicant })
          .then((data) => {
            this.mutableApplicant = { ...data }
            this.savedApplicant = { ...data }
            this.successMessage = "Changes Saved"
          })
          .catch((error) => {
            this.requestError = error
          })
          .finally(() => {
            this.saving = false
          })
      },

      excitementLevelLabel(level) {
        return excitementLevelManager.labelForExcitementLevel(level)
      },

      DeleteApplicant() {
        if (this.deleting) {
          return
        }
        this.deleting = true
        this.successMessage = null
        this.requestError = null
        this.$delete(`/users/${this.applicant.id}`)
          .then(() => {
            this.successMessage = `Deleted ${this.fullName}`
            this.$router.push({ name: "Applicants" })
          })
          .catch((error) => {
            this.requestError = error
          })
          .finally(() => {
            this.deleting = false
          })
      },
      printApplicant() {
        // Redirect to print applicant page
        return this.$router.push({ name: "PrintApplicant", params: { userId: this.applicant.id } })
      }

    }
}
</script>

<style>
.btn {
  margin: 10px;
}

#user-info-submit {
  display: block;
  margin-left: auto;
}

#delete-applicant-link {
  color: red;
}
</style>
