// Confguration docs:
// https://aws-amplify.github.io/docs/js/authentication#manual-setup

import axios from "axios"


export function getCognitoValues() {
  // If we're running dev mode, we'll return the cognito exports from environment variables.
  if (process.env.NODE_ENV === "development") {
      return Promise.resolve({
          "data": {
      VUE_APP_COGNITO_USERPOOL_ID: process.env.VUE_APP_USER_POOL_ID,
      VUE_APP_COGNITO_CLIENT_ID: process.env.VUE_APP_USER_POOL_CLIENT_ID,
      VUE_APP_COGNITO_APP_DOMAIN: process.env.VUE_APP_USER_POOL_DOMAIN,
    }
    })
  }
  // Otherwise, we'll return the cognito exports from a json file.
  return axios.get("/cognitoExports.json")
}

