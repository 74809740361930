import Vue from "vue"
import { Auth } from "aws-amplify"
import Router from "vue-router"
import AddApplicant from "@/components/AddApplicant"
import Main from "@/components/Main"
import MySkills from "@/components/MySkills"
import Skills from "@/components/Skills"
import Applicants from "@/components/Applicants"
import Applicant from "@/components/Applicant"
import PrintApplicant from "@/components/PrintApplicant"
import TestAuthorization from "@/components/TestAuthorization"
import store from "../store.js"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Main",
      component: Main,
      meta: { requiresAuth: false }
    },
    {
      path: "/myskills",
      name: "MySkills",
      component: MySkills,
      meta: {
        requiresAuth: true,
        requiresGroups: ["Applicants"]
      }
    },
    {
      path: "/skills",
      name: "Skills",
      component: Skills,
      meta: {
        requiresAuth: true,
        requiresGroups: ["StaffingTeam", "Recruiters"]
      }
    },
    {
      path: "/applicants",
      name: "Applicants",
      component: Applicants,
      meta: {
        requiresAuth: true,
        requiresGroups: ["StaffingTeam", "Recruiters"]
      }
    },
    {
      path: "/applicants/add",
      name: "AddApplicant",
      component: AddApplicant,
      meta: {
        requiresAuth: true,
        requiresGroups: ["Recruiters"]
      }
    },
    {
      path: "/applicants/:userId",
      name: "Applicant",
      component: Applicant,
      props: true,
      meta: {
        requiresAuth: true,
        requiresGroups: ["StaffingTeam", "Recruiters"]
      }
    },
    {
      path: "/applicants/:userId/print",
      name: "PrintApplicant",
      component: PrintApplicant,
      props: true,
      meta: {
        requiresAuth: true,
        requiresGroups: ["StaffingTeam", "Recruiters"]
      }
    },
    {
      path: "/testAuthorization",
      name: "TestAuthorization",
      component: TestAuthorization,
      meta: { requiresAuth: true }
    }
  ]
})

const defaultPathForSession = function() {
  let name = "Main"
  if (store.getters["signedIn"]) {
    name = store.getters.userIsApplicant ? "MySkills" :
      store.getters.userIsAnler ? "Applicants" : name
  }
  return name
}


// Require authenticated user to access routes with meta.requiresAuth
router.beforeResolve(async (to, from, next) => {

  if (!to.name) {
    // unknown path, redirect to default path

    // save session to store in case this is initial page load:
    try {
      // commit the session (if any) to Vuex store,
      // ignoring exception.
      store.commit("setUserSession", await Auth.currentSession())
    } catch (error) {
      // ignore if not authenticated
      /* eslint-disable no-empty */
    }

    next({ name: defaultPathForSession() })
  } else if (to.matched.some(record => record.meta.requiresAuth)) {

    try {
      // save session to store, raises exception if not authenticated:

      let vuexState = localStorage.getItem("vuex")
      let vuexJsonState = JSON.parse(vuexState)
      const userSession = vuexState && vuexJsonState.userSession !== null ?
        vuexJsonState.userSession :
        (await Auth.currentSession())
      store.commit("setUserSession", userSession)
    } catch (error) {
      console.log(error)
      // clear the session and redirect to Main with
      // requested path as ?redirect query param:
      store.commit("clearUserSession")
      localStorage.setItem("redirect", to.fullPath)
      next({
        name: "Main",
        query: {
          redirect: to.fullPath
        }
      })
    }

    // we are authed, now check user group assignment if needed:
    const groups = to.meta.requiresGroups || []

    if (groups.length && !store.getters.UserInOneOfGroups(groups)) {
      // user in wrong group, redirect ot default landing for the user
      const name = defaultPathForSession()
      if (name !== to.name) {
        next({ name })
      } else {
        next()
      }
    }

      next()


  } else {
    // no auth rules... next
    next()
  }
})

export default router
