import { render, staticRenderFns } from "./ApplicantInfo.vue?vue&type=template&id=01ca2184&"
import script from "./ApplicantInfo.vue?vue&type=script&lang=js&"
export * from "./ApplicantInfo.vue?vue&type=script&lang=js&"
import style0 from "./ApplicantInfo.vue?vue&type=style&index=0&id=01ca2184&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports