<template>
  <div class="user">

    <div v-if="applicant">
      
      <h1>{{ applicant.firstName }} {{ applicant.lastName }}</h1>
      <br />
      <p>
        These are the skills that the applicant has specified;
        the rest are <span style="font-weight:bold">No Experience</span>.
      </p>

      <b-table
        id="applicant-skills-table"
        striped
        sticky-header
        no-border-collapse
        :fields="fields"
        :items="applicant.skills"
        :sort-compare="CompareSkillLevels"
      >
        <template v-slot:table-colgroup>
          <col class="col-skill-group">
          <col class="col-skill-name">
          <col class="col-skill-level">
        </template>
      </b-table>
    </div>

    <div
      v-else
      class="loading-text"
    >
      Loading...
    </div>
  </div>
</template>

<script>
//import axios from 'axios'
import sSkillLevels from "@/assets/SkillLevels"
import { mapGetters } from "vuex"

import excitementLevelManager from "@/utils/excitementLevels"

export default {
  name: "Applicant",

  props: {
    newApplicant: null,
    userId: String,
    successMessage: String
  },

  data() {
    return {
      fields: [
        {
          key: "group",
          name: "Group",
          sortable: true
        },
        {
          key: "name",
          name: "Skill",
          sortable: true
        },
        {
          key: "level",
          name: "Skill Level",
          formatter: this.Level,
          sortable: true
        }
      ],
      applicant: this.newApplicant,
      requestError: null
    }
  },

  computed: {
    ...mapGetters(["userIsApplicant"])
  },

  created() {
    if (this.applicant) {
      return
    }

    if (!this.userId) {
      return
    }

    this.requestError = null
    this.$get("/users/" + this.userId + "/skills")
      .then((data) => {
        data.excitementLevel = excitementLevelManager.normalizedExcitementLevel(data.excitementLevel)

        this.applicant = data
      })
      .catch((error) => {
        this.requestError = error
      })
  },

  methods: {
    Level(value, key, item) {
      const skill = sSkillLevels.find(skl => skl.id == item.level)
      return skill ? skill.name : "unknown"
    },

    CompareSkillLevels(a, b, key) {
      if ("level" != key) {
        return null // Use default sorting
      }
      return ((b.level == a.level) ? 0 : ((a.level > b.level) ? 1 : -1))
    }
  }
}
</script>

<style scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

table, th, td {
  border: 1px solid black;
}

td {
  padding: 2px;
}

.b-table-sticky-header {
  max-height: none !important;
}


</style>

